export class SoldInventoriesModel{
      Invoice_No: number;
      Lot_No:string;
      Auctionname:string;
      Inventory_Id : number;
      Title:string;
      UniqueId:string;
      ConsignorName:string;
      BuyerName:string;
      HSNCode:string;
      Medium:string;
      TotalSalePrice:number;
      Invoice_CustomerId: number;
      Invoice_PaymentStatus:boolean;
      Invoice_ItemName:string
      Invoice_Qty:number
      Invoice_Amount: number;
      Invoice_CGST: number;
      Invoice_SGST: number;
      Invoice_GST: number;
      Invoice_TCS: number;
      Invoice_TotalAmount: number;
      Invoice_TaxableAount: number;
      Invoice_TcsAplicable:boolean;
      Invoice_PaymentTerms:string;
      Invoice_Date:string;
      Invoice_UploadFile:string
      Invoice_INRNO:string
      Invoice_ACKNO:string;
      Invoice_ACKDate:string;
      Invoice_IsActive :boolean;
      Invoice_IsDelete:boolean;
      Type:number;
    }

    export class BuyerProformaInvoiceModel{
      
      BuyerName:string;
      InvoiceNO:string;
      InventoryId:string;
      Date:string;
      UploadAttachment:string;
      FileName:string;
      BillingAddressLine1 :string;
      BillingAddressLine2: string;
      BillingCountry: string;
      BillingState:string;
      BillingLandMark: string;
      BillingPinCode: string;
      BillingCity: string;
      DeliveryAddressLine1: string;
      DeliveryAddressLine2: string;
      DeliveryCountry: string;
      DeliveryState: string;
      DeliveryLandMark: string;
      DeliveryPinCode: string;
      DeliveryCity: string;
      HSNCode:string;
      Lot_No:string;
      emalAddress:string;
      Quantity:number=0;
      Buyer_NonTaxAmount:number=0;
      Buyer_Invoice_TaxableAount:number=0;
      TaxableValue:number=0;
      SGST:number=0;
      IGST:number=0;
      CGST:number=0;
      SGST_Per:number=0;
      IGST_Per:number=0;
      CGST_Per:number=0;
      Gst:number=0;
      GrandTotal:number=0;
      
      TcsApplicable:boolean;
      TcsApplicable_Per:number=0;
      TcsApplicable_Value:number=0;
      PaymentTerms:string;
      Buyer_Desc:string;
      BankDtls:string;

      Buyer_ProformaInvoiceFile:string;
      Buyer_ProformaInvoiceFileName:string;
      Invoice_TaxInvoiceFilePath:string;
      Invoice_TaxInvoiceFileName: string;
      OrderNumber: string;
    }
    export class BuyerPaymentModel{
      BankDetails:string;
      Buyer_Pkey_ID:number;
      Buyer_Invoice_Pkey_ID:number;
      Buyer_Inventory_Id:string;
      Date:string;
      Amount:number;
      TransactionId:string;
      UploadAtachment:string;
      FileName:string;
      BankName:string;
      BranchName:string;
      AccountNumber:number;
      AccountHolderName:string;
      IFSCcode:string;
      SWIFTcode:string;
    }

    
    export class ConsignorTaxInvoiceModel{
      //[x: string]: any;
      ConsignorName:string;
      Consignor_PKey_ID:number;
      InventoryId:string;
      Buyer_Invoice_Pkey_ID:number;
      InvoiceNO:string;
      Date:string;
      UploadAttachment:string;
      FileName:string;
      Consignor_Desc:string;
      ConsignorAddressLine1: string;
      ConsignorAddressLine2: string;
      ConsignorCountry: string;
      ConsignorState: string;
      ConsignorLandMark: string;
      ConsignorPinCode: string;
      ConsignorCity: string;
      HSNCode:string;
      Lot_No:string;
      Consignor_CINNO:string;
      ConsignorGstNo:string;
      ConsignorPanNo:string;
      Quantity:number=0;
      Consignor_NonTaxAmount:number=0;
      TaxableValue:number=0;
      SGST:number=0;
      IGST:number=0;
      CGST:number=0;
      SGST_Per:number=0;
      IGST_Per:number=0;
      CGST_Per:number=0;
      GrandTotal:number=0;
      
      TcsApplicable:boolean;
      TcsApplicable_Per:number=0;
      TcsApplicable_Value:number=0;
      PaymentTerms:string;
      ConsignorBankDtls:string;
      AstaCompanyDtls:string;
     
      Consignor_InvoiceFile:string;
      Consignor_InvoiceFileName:string;
      NameofBeneficiary :string;
      BankName :string;
      BankAddress:string;
      AccountNo :string;
      SwiftCode :string;
      RTGS_NEFT_IFSCCode:string;
    }

    export class ConsignorPaymentModel{
      Consignor_PKey_ID:number;
      Consignor_Invoice_PKey_ID:number;
      Consignor_Inventory_Id:string;
      Date:string;
      Amount:number;
      TransactionId:string;
      UploadAtachment:string;
      FileName:string;
      BankDetails:string;
      BankName:string;
      BranchName:string;
      AccountNumber:number;
      AccountHolderName:string;
      IFSCcode:string;
      SWIFTcode:string;
    }

    export class TaxInvoiceModel{
      Buyer_Inventory_Id:string;
      IRN_No:string;
      ACK_No:string;
      ACk_Date:string;
      UploadAtachment:string;
      Invoice_FileName:string;
      BankDetails:string;
      Buyer_TaxInvoiceNo:String;
    }

    export class LogisticsModel{
      Logistics_Pkey_ID:number;
      Logistics_InventoryId:string;
      ApprovalFromTeam:boolean;
      Vendor:string;
      VendorId:string;
      Logistics_Person_Incharge:string;
      Amount:string;
      //UploadAtachment:string;
      InventoryId:string;
      PickUpDate:string;
      PickUpTime:string;
      Locatoin:string;
      TypeOfModeId:string;
      TypeOfMode:string;
      TaxPayable:number;
      Logistics_TrackingStatus:number;
    //Eway Bill Generation:
    UploadAttachment:string;
    FileName:string;
    EwayUploadFiles:string;
    EwayFileName:string;
    EwayBillNo:string;
    EwayGeneratedDate:string;
    EwayDocumentDtls:string;
    EwayTransportedId:string;
    EwayVehicleDtls:string;
    TransportedId:string;
    CopmanyName:string;
    shipment_EwayBillNo:string;
    shipment_EwayGeneratedDate:string;
    shipment_EwayDocumentDtls:string;
    shipment_EwayTransportedId:string;
    shipment_EwayVehicleDtls:string;
    shipmentCompanyName:string;
    shipmentTrackingNo:string;
    shipment_EwaypartBUploadFiles:string;
      shipment_EwaypartBFileName: string;
      DeliveryDate: string;
    }
   export class DeliveredModel{
Date:string;
    }
